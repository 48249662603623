/*
At this moment this component is explicitly used to make space on the bottom
Eventually will be a legitimate footer with content
*/
import React from 'react';

const Footer = () => {
    return (
        <div> 
            <br/>
            <br/>
        </div>
    );
}

export default Footer;